import { GET, POST, PUT } from '@/config/fetch'

// 汇报人列表列表
export const sendUserList = () => GET('/front/dailylog/v1/report/sendUserList')

// 汇报按人看-计划分页列表查询
export const userPlanList = (userId: string, planType: number, params: any) =>
  GET(`/front/dailylog/v1/report/pageListByUser/${userId}/${planType}`, params)

// 导出汇报按人看-计划分页
export const exportUserPlanList = (
  userId: string,
  planType: number,
  params: any,
  fileName: string,
) =>
  GET(
    `/front/dailylog/v1/report/exportExcelByUser/${userId}/${planType}`,
    { ...params, fileName },
    {
      responseType: 'blob',
    },
  )

// 汇报汇总看-计划分页列表查询
export const gatherPlanList = (planType: number, params: any) =>
  GET(`/front/dailylog/v1/report/pageListOfGather/${planType}`, params)

// 导出汇报按人看-计划分页
export const exportGatherPlanList = (
  planType: number,
  params: any,
  fileName: string,
) =>
  GET(
    `/front/dailylog/v1/report/exportExcelOfGather/${planType}`,
    { ...params, fileName },
    {
      responseType: 'blob',
    },
  )
