


















































































import { Component, Mixins } from 'vue-property-decorator'
import MemberMixin from '../../mixins/MemberMixin'
import moment from 'moment'

@Component
export default class WeekPlan extends Mixins(MemberMixin) {
  // 计划类型 1年计划 2月计划 3周计划 4日计划
  private planType: number = 3

  private columns = [
    {
      title: '日期',
      width: '140px',
      scopedSlots: { customRender: 'createTimeSlot' },
    },
    {
      title: '本周总结',
      dataIndex: 'thisWeekContent',
      scopedSlots: { customRender: 'thisWeekSlot' },
    },
    {
      title: '下周计划',
      dataIndex: 'nextWeekContent',
      scopedSlots: { customRender: 'nextWeekSlot' },
      customCell: () => {
        return {
          style: {
            'min-width': '100px',
          },
        }
      },
    },
    {
      title: '需要协调的资源或帮助',
      dataIndex: 'helpContent',
      width:'80px',
      scopedSlots: { customRender: 'helpContentSlot' },
    },
  ]

  formatTime(timestamp: number) {
    const current = moment()
    const currentDateStr = current.format('YYYY-MM-DD')
    const date = moment(timestamp)

    if (date.isSame(currentDateStr, 'year')) {
      return date.format('M月D日')
    }
    return date.format('YYYY年M月D日')
  }
}
