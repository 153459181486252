























































































import { Component, Watch, Vue, ModelSync } from 'vue-property-decorator'
import tupianIcon from '@/assets/year_images/icon_tupian.svg'
import pdfIcon from '@/assets/year_images/icon_wenjianpdf.svg'
import wordIcon from '@/assets/year_images/icon_wenjianword.svg'

import { uuid } from '@/utils/util'
import moment from 'moment'
import { downLoadURlFN } from '@/utils'

@Component
export default class FileList extends Vue {
  @ModelSync('value', 'change', { type: [Object, Array], default: () => [] })
  uploadedList!: Array<any>

  disabled = true
  listType = 'text'

  private get displayList() {
    if (!this.uploadedList || !Array.isArray(this.uploadedList)) {
      return []
    }
    return this.uploadedList.map((item: any) => {
      const result = {
        uid: item.id || item.uid || uuid(),
        file: {
          name: item.attachmentName || item.fileName || item.name,
          attachmentUrl: item.attachmentUrl,
          size: item.attachmentSize,
          createTime: item.createTime,
        },
        url: item.attachmentUrl,
        status: 'done',
        percent: 0,
        rawData: item, // rawData存在代表是回显的文件
      }
      return result
    })
  }

  getFileImg(file: any) {
    const name = file.name ?? ''
    const wordFile = ['.xls', '.xlsx', '.doc', '.docx']
    const jpgFile = ['.jpg', '.jpeg', '.png']
    if (
      wordFile.filter((word) => name.toLowerCase().endsWith(word)).length > 0
    ) {
      return wordIcon
    } else if (
      jpgFile.filter((jpg) => name.toLowerCase().endsWith(jpg)).length > 0
    ) {
      return tupianIcon
    } else {
      return pdfIcon
    }
  }

  getFileSize(file: any) {
    const size = Math.max(0.01, file.size / 1024 / 1024)
    return size.toFixed(2) + 'M'
  }

  getFileSuffix(file: any) {
    const { fileName, name } = file
    const _name = fileName || name
    return _name.substring(_name.lastIndexOf('.') + 1, _name.length)
  }

  downloading: boolean = false
  currentFileItem: any = {}
  download(file: any) {
    this.currentFileItem = file
    this.downloading = true
    setTimeout(async () => {
      try {
        const downloadUrl = file.rawData.showUrl
        await downLoadURlFN(downloadUrl, file.rawData.attachmentName)
      } catch (error) {
        console.error('downLoadURlFN', error)
      } finally {
        this.downloading = false
      }
    }, 300)
  }

  previewVisible: boolean = false
  previewUrl: string = ''
  preview(file: any) {
    this.currentFileItem = file
    const [rawFileUrl, rawFileToken] = file.rawData.showUrl.split('?')
    const fileUrl = decodeURIComponent(rawFileUrl)
    const url =
      process.env.VUE_APP_PREVIEW_FILE_URL +
      encodeURIComponent(Base64.encode(fileUrl + '?' + rawFileToken))
    this.previewUrl = url
    this.previewVisible = true
  }
  cancelPreview() {
    this.previewUrl = ''
    this.previewVisible = false
    this.fullscreen = false
  }
  fullscreen: boolean = false
  toogleFullscreen() {
    this.fullscreen = !this.fullscreen
  }
}
