







































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { debounce } from 'lodash'
import { sendUserList } from '@/api/report'
import DailyPlan from './DailyPlan.vue'
import WeekPlan from './WeekPlan.vue'
import MonthPlan from './MonthPlan.vue'
import YearPlan from './YearPlan.vue'

@Component({
  components: { DailyPlan, WeekPlan, MonthPlan, YearPlan },
})
export default class Report extends Vue {
  @Prop({ type: String, required: true }) reportType!: string
  typeChange(e: any) {
    this.$emit('typeChange', e.target.value)
  }

  activeKey: string = 'day'

  fetching: boolean = false
  keyword: string = ''
  throttleFilterContacts: any = null
  checkedContacts: any = {}
  contactsList: any[] = []
  displayContactsList: any[] = []
  get currentUser() {
    const { id, realName, avatar } = this.$store.state.user.userInfo
    return {
      sharerId: id,
      sharerName: realName,
      sharerHeadSculpture: avatar,
    }
  }

  selectContacts(item: any) {
    this.checkedContacts = this.checkedContacts = item
    this.activeKey = 'day'
  }

  filterContacts() {
    this.displayContactsList = this.contactsList.filter((item) =>
      item.sharerName.includes(this.keyword),
    )
  }

  async queryContacts() {
    this.fetching = true
    try {
      const [err, res] = await sendUserList()
      if (err) throw err
      this.contactsList = res
      this.displayContactsList = [...this.contactsList]
    } catch (e: any) {
      this.$message.error(e.message)
    } finally {
      this.fetching = false
    }
  }

  created() {
    this.throttleFilterContacts = debounce(this.filterContacts, 500)
    this.selectContacts(this.currentUser)
    this.queryContacts()
  }
}
