







































import moment from 'moment'
import { Component, Vue, Prop, ModelSync, Watch } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class PlanDetailModal extends Vue {
  @ModelSync('value', 'input', { type: Boolean, default: false })
  modalVisible!: boolean
  @Prop({ type: Object, required: true }) checkedContacts!: any
  @Prop({ type: Object, default: () => ({}) }) planInfo!: any
  @Prop({ type: Number, default: 1 }) type!: number

  Messtype: any = {
    1: '年计划',
    2: '月计划',
    3: '周计划',
    4: '日志',
  }
  get getYbptToken() {
    return this.$store.state.user.userInfo.ybptToken
  }
  get getColor() {
    if (this.type == 1) {
      return ' #ECB27D'
    } else if (this.type == 2) {
      return '#69BA88'
    } else if (this.type == 3) {
      return '#65B3D9'
    } else if (this.type == 4) {
      return ' #66A0E8'
    } else {
      return ''
    }
  }
  get getUrl() {
    let url = `${location.origin}${process.env.BASE_URL}detail?planId=${this.planInfo.id}&type=${this.type}`
    if (this.type == 1) {
       url += `&year=${moment(this.planInfo.createTime).format('YYYY')}`
    }
    return url
  }

  @Watch('value')
  handleValueChange(nval: boolean) {
    if (nval) {
    }
  }

  handleClose() {
    this.$emit('input', false)
  }
}
