// @ts-nocheck
import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash-es'
import moment from 'moment'
import { uuid, filterObj } from '@/utils/util'

import {
  gatherPlanList,
  exportGatherPlanList,
  userPlanList,
} from '@/api/report'
import PlanDetail from '../components/Detail.vue'
import FileList from '../components/FileList.vue'

const defaultTime = {
  1: [moment().subtract(1, 'year').format('YYYY'), moment().format('YYYY')],
  2: [
    moment().subtract(11, 'month').format('YYYY-MM'),
    moment().format('YYYY-MM'),
  ],
  3: [
    moment().subtract(14, 'days').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ],
  4: [
    moment().subtract(14, 'days').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ],
}

const planTypeTitle = {
  1: '年',
  2: '月',
  3: '周',
  4: '日',
}

@Component({
  components: { PlanDetail, FileList },
})
export default class SummaryMixin extends Vue {
  @Prop({ type: Object, required: true }) checkedPlanType!: any
  @Ref() readonly table!: any
  private get defaultParam() {
    const planType = this.checkedPlanType.type
    return {
      time: [...defaultTime[planType]],
      sharerIdList: [],
    }
  }
  private queryParam: any = { time: [], sharerIdList: [] }
  private async apiFun(params: any) {
    const [err, res] = await gatherPlanList(this.checkedPlanType.type, params)
    if (err) return [err, res]
    return [
      err,
      {
        ...res,
        records: res.records.map((item) => ({ ...item, key: uuid() })),
      },
    ]
  }
  setParams() {
    const { time = [], sharerIdList = [] } = this.queryParam
    return {
      startTime: time[0] ?? '',
      endTime: time[1] ?? '',
      sharerIdList: sharerIdList.map((item) => item.receiverId).join(),
    }
  }

  // 查询
  @Watch('queryParam.time')
  refreshTable(needRefreshTable: boolean = true) {
    // this.table.refresh(needRefreshTable)
    this.refresh(needRefreshTable)
  }

  @Watch('queryParam.sharerIdList')
  refreshTableData() {
    // this.table.refresh(true)
    this.refresh(true)
  }

  // 详情
  detailModalVisible: boolean = false
  planInfo: any = {}
  get checkedContacts() {
    return {
      sharerName: this.planInfo.sharerName ?? '',
      sharerHeadSculpture: this.planInfo.sharerHeadSculpture ?? '',
    }
  }
  customRow(record: any, index: string) {
    return {
      on: {
        click: () => {
          this.planInfo = {
            ...record,
          }
          this.detailModalVisible = true
        },
      },
    }
  }

  // 导出
  exportLoading: boolean = false
  async handleExport() {
    // 计划类型 1年计划 2月计划 3周计划 4日计划
    this.exportLoading = true
    const params = this.setParams()
    await exportGatherPlanList(
      this.checkedPlanType.type,
      {
        ...params,
      },
      `${planTypeTitle[this.checkedPlanType.type]}计划汇总`,
    )
    this.exportLoading = false
  }

  mounted() {
    this.queryParam = { ...cloneDeep(this.defaultParam) }
  }

  // 分页逻辑
  private loading: boolean = false
  private currentPage: number = 1
  private pageSize: number = 10
  private total: number = 100
  protected tableData: any[] = []
  private handlePaginationChange(page: any, pageSize: any) {
    this.currentPage = page
    this.pageSize = pageSize
    this.getData(false)
  }
  private async getData(resetParam?: boolean) {
    if (this.apiFun) {
      if (this.loading) return
      this.loading = true
      const pagination = {
        current: this.currentPage,
        size: this.pageSize,
      }
      let query: any = {}
      const queryParam = this.setParams ? this.setParams(pagination) : {}
      if (resetParam || Object.keys(this.shadowQuery).length === 0) {
        query = this.shadowQuery = {
          ...queryParam,
        }
      } else {
        query = {
          ...this.shadowQuery,
        }
        query.desc = queryParam.desc
        query.asc = queryParam.asc
      }
      if (query['current'] === undefined) {
        query = {
          ...pagination,
          ...query,
        }
      }
      const [err, res] = await this.apiFun(filterObj(query))
      this.loading = false
      if (!err) {
        this.total = res.total
        this.tableData = res.records
      }
    }
  }
  //刷新数据(使用ref外部调用)
  // val true为点击查询或重置按钮
  private refresh(val: boolean = false) {
    if (this.apiFun) {
      if (val) {
        this.currentPage = 1
        this.getData(val)
      } else {
        this.getData(val)
      }
    }
  }
  get displayTableData() {
    const result = new Map()
    this.tableData.forEach((item) => {
      const dateStr = this.formatTime(item.createTime)
      if (!result.has(dateStr)) {
        result.set(dateStr, [])
      }
      result.get(dateStr).push(item)
    })
    return result
  }
}
