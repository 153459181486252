



















































































































import { Component, Mixins } from 'vue-property-decorator'
import SummaryMixin from '../../mixins/SummaryMixin'
import moment from 'moment'

@Component
export default class YearPlan extends Mixins(SummaryMixin) {
  // 计划类型 1年计划 2月计划 3周计划 4日计划
  private planType: number = 1

  private columns = [
    {
      title: '人员',
      width: '140px',
      scopedSlots: { customRender: 'createTimeSlot' },
    },
    {
      title: '内容',
      scopedSlots: { customRender: 'contentSlot' },
    },
    {
      title: '附件',
      width: '300px',
      scopedSlots: { customRender: 'fileSlot' },
    },
  ]

  formatTime(timestamp: number) {
    const date = moment(timestamp)
    return date.format('YYYY年')
  }
}
