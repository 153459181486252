







































import { Component, Vue, Prop } from 'vue-property-decorator'
import DailyPlan from './DailyPlan.vue'
import WeekPlan from './WeekPlan.vue'
import MonthPlan from './MonthPlan.vue'
import YearPlan from './YearPlan.vue'
import daySvg from '@/assets/images/icon_日.svg'
import weekSvg from '@/assets/images/icon_周.svg'
import monthSvg from '@/assets/images/icon_月.svg'
import yearSvg from '@/assets/images/icon_年.svg'

@Component({
  components: { DailyPlan, WeekPlan, MonthPlan, YearPlan },
})
export default class Report extends Vue {
  @Prop({ type: String, required: true }) reportType!: string
  typeChange(e: any) {
    this.$emit('typeChange', e.target.value)
  }

  currentIndex: number = 0
  planTypeList = [
    {
      type: 4,
      title: '日计划',
      icon: daySvg,
      comp: 'DailyPlan',
    },
    {
      type: 3,
      title: '周计划',
      icon: weekSvg,
      comp: 'WeekPlan',
    },
    {
      type: 2,
      title: '月计划',
      icon: monthSvg,
      comp: 'MonthPlan',
    },
    {
      type: 1,
      title: '年计划',
      icon: yearSvg,
      comp: 'YearPlan',
    },
  ]

  get currentPlanType() {
    return this.planTypeList[this.currentIndex]
  }
  changePlanType(index: number) {
    this.currentIndex = index
  }
}
