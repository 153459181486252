






































































import { Component, Mixins } from 'vue-property-decorator'
import MemberMixin from '../../mixins/MemberMixin'
import moment from 'moment'

@Component
export default class DailyPlan extends Mixins(MemberMixin) {
  // 计划类型 1年计划 2月计划 3周计划 4日计划
  private planType: number = 4

  private columns = [
    {
      title: '日期',
      width: '140px',
      scopedSlots: { customRender: 'createTimeSlot' },
    },
    {
      title: '内容',
      scopedSlots: { customRender: 'contentSlot' },
    },
    {
      title: '附件',
      width: '300px',
      scopedSlots: { customRender: 'fileSlot' },
    },
  ]

  formatTime(timestamp: number) {
    const current = moment()
    const currentDateStr = current.format('YYYY-MM-DD')
    const date = moment(timestamp)

    if (date.isSame(currentDateStr, 'year')) {
      return date.format('M月D日')
    }
    return date.format('YYYY年M月D日')
  }
}
