// @ts-nocheck
import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash-es'
import moment from 'moment'
import { uuid } from '@/utils/util'
import { userPlanList, exportUserPlanList } from '@/api/report'
import PlanDetail from '../components/Detail.vue'
import FileList from '../components/FileList.vue'

const defaultTime = {
  1: [moment().subtract(1, 'year').format('YYYY'), moment().format('YYYY')],
  2: [
    moment().subtract(11, 'month').format('YYYY-MM'),
    moment().format('YYYY-MM'),
  ],
  3: [
    moment().subtract(14, 'days').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ],
  4: [
    moment().subtract(14, 'days').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ],
}

const planTypeTitle = {
  1: '年',
  2: '月',
  3: '周',
  4: '日',
}

@Component({
  components: { PlanDetail, FileList },
})
export default class MemberMixin extends Vue {
  @Prop({ type: Object, required: true }) checkedContacts!: any
  @Ref() readonly table!: any
  private get defaultParam() {
    const planType = this.planType
    return {
      time: [...defaultTime[planType]],
    }
  }
  private queryParam: any = { time: [] }
  private async apiFun(params: any) {
    const [err, res] = await userPlanList(
      this.checkedContacts.sharerId,
      this.planType,
      params,
    )
    if (err) return [err, res]
    return [
      err,
      {
        ...res,
        records: res.records.map((item) => ({ ...item, key: uuid() })),
      },
    ]
  }
  setParams() {
    const { time = [] } = this.queryParam
    return {
      startTime: time[0] ?? '',
      endTime: time[1] ?? '',
    }
  }
  // 查询
  @Watch('queryParam.time')
  refreshTable(needRefreshTable: boolean = true) {
    this.table.refresh(needRefreshTable)
  }

  @Watch('checkedContacts.sharerId')
  refreshTableData() {
    this.queryParam = { ...cloneDeep(this.defaultParam) }
    this.table.refresh(true)
  }

  // 详情
  detailModalVisible: boolean = false
  planInfo: any = {}
  customRow(record: any, index: string) {
    return {
      on: {
        click: () => {
          this.planInfo = {
            ...record,
          }
          this.detailModalVisible = true
        },
      },
    }
  }

  exportLoading: boolean = false
  async handleExport() {
    // 计划类型 1年计划 2月计划 3周计划 4日计划
    this.exportLoading = true
    const { time = [] } = this.queryParam
    const { sharerName } = this.checkedContacts
    await exportUserPlanList(
      this.checkedContacts.sharerId,
      this.planType,
      {
        startTime: time[0] ?? '',
        endTime: time[1] ?? '',
      },
      `${sharerName}的${planTypeTitle[this.planType]}计划`,
    )
    this.exportLoading = false
  }

  mounted() {
    this.queryParam = { ...cloneDeep(this.defaultParam) }
  }
}
